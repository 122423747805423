@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');

html {
  scroll-behavior: smooth;
  font-family:'Roboto Mono';
}
.App-body {
  background-color: #282c34c4;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: black;
}

footer {
  background-color: #282c34;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
  color: white;
}

p {
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-family:'Roboto Mono';
  font-size: 16px;
}

h1 {
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-family:'Roboto Mono';
  display: flex;
  flex-direction: row;
  justify-content: left;
}

h2 {
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-family:'Roboto Mono';
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.textCenter {
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-family:'Roboto Mono';
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

a {
  margin: 1px;
  font-family:'Roboto Mono';
  text-decoration: none;
  color: white;
}

.AlignLeft {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: baseline;
  padding: 10px;
  margin-right: 5%;
}
.AlignCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  padding: 10px;
}