.Text-Content.Other {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}

.Other.Left.Row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}

.Other.Left.Column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: centere;
    justify-content: space-between;
}

.Other-Box.Row {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: left;
    margin: 2%;
    width: 50%;
    margin-top: 20px;
    min-height: 100px;
    min-width: none;
    overflow: hidden;
}

.Other-Box.Column{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    margin: 2%;
    width: 100%;
    margin-top: 20px;
    min-height: 100px;
    min-width: none;
    overflow: hidden;
}

.Other-Box.Row{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    margin: 2%;
    width: 80%;
    margin-top: 20px;
    min-height: 100px;
    min-width: none;
    overflow: hidden;
}
