

.SkillsItem {
    border: 1px solid rgb(179, 175, 175);
    border-radius: 20px;
    margin-top: 5%;
    margin-right: 5%;
    width: 125px;
    box-shadow: 2px 2px 2px 2px black;
    transition: background-color .2s ease-out;
    cursor: pointer;
}

.SkillsItem:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}

.column {
    display: flex;
    flex-direction: column;
}

.column.left {
    margin-right: 2%;
}

.column.right {
    margin-left: 2%;
    padding-right: 4%;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
}

.icon {
    height: 100%;
    object-fit: scale-down;
}

.IconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.StarsContainer {
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;
    justify-content: center;
    width: 100%;
}

.SkillName {
    display: flex;
    justify-content: center;
}

/*Colors for technologies*/
/*Python*/
.SkillsItem.Python.Passive {
    background-color: #f5f5f5;
    color: #2a2a2a;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.Python.Passive:hover {
        background-color: #717171;
        color: #f5f5f5;
        box-shadow: 2px 2px 2px 2px white;
        cursor: pointer;
    }
}

.SkillsItem.Python.Active {
    background-color: #2a2a2a;
    color: #f5f5f5;
    box-shadow: 2px 2px 2px 2px white;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.Python.Active:hover {
        color: #f5f5f5;
        cursor: pointer;
    }
}

/*Java*/
.SkillsItem.Java.Passive {
    background-color: #f5f5f5;
    color: #2a2a2a;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.Java.Passive:hover {
        background-color: #717171;
        color: #f5f5f5;
        box-shadow: 2px 2px 2px 2px white;
        cursor: pointer;
    }
}

.SkillsItem.Java.Active {
    background-color: #2a2a2a;
    color: #f5f5f5;
    box-shadow: 2px 2px 2px 2px white;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.Java.Active:hover {
        color: #f5f5f5;
        cursor: pointer;
    }
}

/*JavaScript*/
.SkillsItem.JavaScript.Passive {
    background-color: #f5f5f5;
    color: #2a2a2a;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.JavaScript.Passive:hover {
        background-color: #717171;
        color: #f5f5f5;
        box-shadow: 2px 2px 2px 2px white;
        cursor: pointer;
    }
}

.SkillsItem.JavaScript.Active {
    background-color: #2a2a2a;
    color: #f5f5f5;
    box-shadow: 2px 2px 2px 2px white;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.JavaScript.Active:hover {
        color: #f5f5f5;
        cursor: pointer;
    }
}

/*PostgreSQL*/
.SkillsItem.PostgreSQL.Passive {
    background-color: #f5f5f5;
    color: #2a2a2a;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.PostgreSQL.Passive:hover {
        background-color: #717171;
        color: #f5f5f5;
        box-shadow: 2px 2px 2px 2px white;
        cursor: pointer;
    }
}

.SkillsItem.PostgreSQL.Active {
    background-color: #2a2a2a;
    color: #f5f5f5;
    box-shadow: 2px 2px 2px 2px white;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.PostgreSQL.Active:hover {
        color: #f5f5f5;
        cursor: pointer;
    }
}

/*Git*/
.SkillsItem.Git.Passive {
    background-color: #f5f5f5;
    color: #2a2a2a;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.Git.Passive:hover {
        background-color: #717171;
        color: #f5f5f5;
        box-shadow: 2px 2px 2px 2px white;
        cursor: pointer;
    }
}

.SkillsItem.Git.Active {
    background-color: #2a2a2a;
    color: #f5f5f5;
    box-shadow: 2px 2px 2px 2px white;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.Git.Active:hover {
        color: #f5f5f5;
        cursor: pointer;
    }
}

/*Linux*/
.SkillsItem.Linux.Passive {
    background-color: #f5f5f5;
    color: #2a2a2a;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.Linux.Passive:hover {
        background-color: #717171;
        color: #f5f5f5;
        box-shadow: 2px 2px 2px 2px white;
        cursor: pointer;
    }
}

.SkillsItem.Linux.Active {
    background-color: #2a2a2a;
    color: #f5f5f5;
    box-shadow: 2px 2px 2px 2px white;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.Linux.Active:hover {
        color: #f5f5f5;
        cursor: pointer;
    }
}

/*AWS*/
.SkillsItem.AWS.Passive {
    background-color: #f5f5f5;
    color: #2a2a2a;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.AWS.Passive:hover {
        background-color: #717171;
        color: #f5f5f5;
        box-shadow: 2px 2px 2px 2px white;
        cursor: pointer;
    }
}

.SkillsItem.AWS.Active {
    background-color: #2a2a2a;
    color: #f5f5f5;
    box-shadow: 2px 2px 2px 2px white;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.AWS.Active:hover {
        color: #f5f5f5;
        cursor: pointer;
    }
}

/*Docker*/
.SkillsItem.Docker.Passive {
    background-color: #f5f5f5;
    color: #2a2a2a;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.Docker.Passive:hover {
        background-color: #717171;
        color: #f5f5f5;
        box-shadow: 2px 2px 2px 2px white;
        cursor: pointer;
    }
}

.SkillsItem.Docker.Active {
    background-color: #2a2a2a;
    color: #f5f5f5;
    box-shadow: 2px 2px 2px 2px white;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.Docker.Active:hover {
        color: #f5f5f5;
        cursor: pointer;
    }
}


/*Bash*/
.SkillsItem.Bash.Passive {
    background-color: #f5f5f5;
    color: #2a2a2a;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.Bash.Passive:hover {
        background-color: #717171;
        color: #f5f5f5;
        box-shadow: 2px 2px 2px 2px white;
        cursor: pointer;
    }
}

.SkillsItem.Bash.Active {
    background-color: #2a2a2a;
    color: #f5f5f5;
    box-shadow: 2px 2px 2px 2px white;
}

@media (hover: hover) and (pointer: fine) {
    .SkillsItem.Bash.Active:hover {
        color: #f5f5f5;
        cursor: pointer;
    }
}
