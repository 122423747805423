.MenuItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    color: white;
}

.MenuItem.Active {
    background-color: white;
    color: black;
}

@media (hover: hover) and (pointer: fine) {
    .MenuItem:hover {
        background-color: white;
        color: black;
        cursor: pointer;
    }
  }

.color {
    background-color: rgb(0, 26, 128);
    cursor: pointer;
}