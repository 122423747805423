.ProjectItemBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

.ProjectArrow {
    display:block;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    opacity: 0.5;
}

.Content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.ProjectArrow.l {
    left: 0;
}

.ProjectArrow.r {
    right: 0;
}

.Slider {
    display: flex;
    flex-direction: column;
    width:100%;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.my-node-enter.toLeft {
    width:100%;
    display: flex;
    flex-direction: column;
    left: 0;
    opacity: 0;
}

.my-node-enter.toRight {
    width:100%;
    display: flex;
    flex-direction: column;
    right: 0;
    opacity: 0;
}

.my-node-enter-active.toLeft {
    opacity: 1;
    transition: opacity 300ms;
    animation-duration: 0.3s;
    animation-name: slideLeft;
}

.my-node-enter-active.toRight {
    opacity: 1;
    transition: opacity 300ms;
    animation-duration: 0.3s;
    animation-name: slideRight;
}

.my-node-exit.toLeft {
    width:100%;
    display: flex;
    flex-direction: column;
    opacity: 1;
}

.my-node-exit.toRight {
    width:100%;
    display: flex;
    flex-direction: column;
    left: 0;
    opacity: 1;
}

.my-node-exit-active.toRight {
    opacity: 0;
    transition: opacity 300ms;
    animation-duration: 0.3s;
    animation-name: outRight;
}

.my-node-exit-active.toLeft {
    opacity: 0;
    transition: opacity 300ms;
    animation-duration: 0.3s;
    animation-name: outLeft;
}

@keyframes outRight {
    from {
        margin-right: 0%;
        width: 100%;
    }
    
    to {
        margin-right: 150%;
        width: 100%;
    }
  }

@keyframes outLeft {
    from {
        margin-left: 0%;
        width: 100%;
    }

    to {
        margin-left: 150%;
        width: 100%;
    }
}

  @keyframes slideRight {
    from {
        margin-left: 150%;
        width: 100%;
    }
    
    to {
        margin-left: 0%;
        width: 100%;
    }
  }

@keyframes slideLeft {
from {
    margin-right: 150%;
    width: 100%;
}

to {
    margin-right: 0%;
    width: 100%;
}
}