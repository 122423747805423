.Project-Box {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    margin: 2%;
    width: 50%;
    margin-top: 20px;
    min-height: 100px;
    min-width: none;
    overflow: hidden;
}

.Project-Box.Column {
    width: 100%;
    flex-direction: row;
}

.Project-Box.Row {
    justify-content: left;
}

.Text-Box.Column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.Projects.Text-Content {
    width: 100%;
    display: block;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Text-Box.Row {
    display: flex;
    max-width: 40%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.Projects.Row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
