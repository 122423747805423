.Menu {
    min-width: 95%;
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
}

.Menu.Center {
    flex-direction: row-reverse;
    align-items: baseline;
    justify-content: center;
    float:inline-start
}

.Menu.Right {
    flex-direction: row-reverse;
    align-items: right;
    justify-content: right;
    float: right;
}