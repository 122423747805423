.App-body {
    background-color: #282c34c4;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: top;
    justify-content: top;
    font-size: 14px;
    color: black;
  }

li {
    list-style: none;
    margin-top: 0;
  }

ul {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    padding-left: 4%;
    padding-right: 4%;
  }

  .MainContainer {
      margin-top: -5vh;
  }