.Nav {
    position: sticky;
    top: 0;
    z-index: 2;
    width: 100%;
    background-color: #282c34;
    min-height: 5vh;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: right;
    font-size: 14px;
    color: white;
    box-shadow: 2px 2px grey;
}
  
