@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');

h3 {
    margin-left: 1vw;
    margin-right: 1vw;
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-family:'Roboto Mono';
}

.Whole-Content {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-size: 14px;
    color: black;
    width: 100%;
}

.Text-Content {
    border: 3px solid white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    margin-left: 5%;
    margin-right:5%;
}

.Text-Content.Main {
    min-width: 50vw;
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;
    
}

.Topic{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
}

.Row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.Column {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Skills {
    margin-top: 5%;
    margin-bottom: 5%;
}

.Dense {
    margin-top: 1px;
    margin-bottom: 1px;
    font-size: 15px;
}

.Left {
    display: flex;
    flex-direction: row;
    min-width: 30%;
    margin-bottom: 5vh;
    margin-right: 5vh;
    margin-left: 5vh;
    flex: 1;
}
.Right{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    margin-left: 5%;
    margin-right: 5%;
    max-width: 1000px;
    overflow-y: auto;
    flex: 1;
}

#Technologies {
    margin-left: 5vh;
    margin-bottom: 5vh;
}

.SkillsText {
    display: flex;
    background-color: white;
    color: black;
    border: 1px solid rgb(179, 175, 175);
    border-radius: 20px;
    box-shadow: 2px 2px 2px 2px black;
    margin-bottom: 5%;
    margin-top: 8vh;
    transition: background-color .6s ease-out;
    width: auto;
    height: auto;
    min-height: 200px;
}

.SkillsText.Python{
    background-color: #FFE873;
    color: rgb(0, 0, 0);
}

.SkillsText.Java{
    background-color: rgba(255, 244, 230, 0.809);
    color: rgb(0, 0, 0);
}

.SkillsText.JavaScript{
    background-color: #F0D84F;
    color: rgb(0, 0, 0);
}

.SkillsText.PostgreSQL{
    background-color: white;
    color: rgb(0, 0, 0);
}

.SkillsText.Git{
    background-color: #f34f29;
    color: rgb(0, 0, 0);
}

.SkillsText.AWS{
    background-color: #FF9900;
    color: rgb(0, 0, 0);
}

.Picture-Content {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    margin: 2%;
    width: 100%;
}

.Picture {
    width: 100%;
    object-fit: scale-down;
}

.PictureSmall {
    width: 100%;
    object-fit: scale-down;
}

.Button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20vw;
    min-width: 200px;
    border: 2px solid black;
    border-radius: 20px;
    cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
    .Button:hover {
        background-color: black;
        color: white;
        cursor: pointer;
    }
}


.Bottom-Section {
    height: 10vw;
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    margin-top: 10px;
}

.Center-Section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SkillsTextContent {
    padding:10px;
}